<mat-toolbar color="primary">
  <mat-toolbar-row class="header" *ngIf="config$ | async as config">
    <span class="header__config">
      <button mat-button class="header__settings-button" *ngIf="config.settingsAction">
        <mat-icon aria-hidden="false" aria-label="Settings" (click)="config.settingsAction()">settings</mat-icon>
      </button>
      Stream Reach
    </span>
    <span class="header__logo"><img src="/assets/img/endpoint-logo.svg"/></span>
    <span class="header__info"></span>
  </mat-toolbar-row>
</mat-toolbar>
