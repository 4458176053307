import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReachItem } from '../../../../../../client/src/models/ReachItem';
import { IReachResponse } from '../interfaces/reach.interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class ReachService {

  constructor(private http: HttpClient) {}

  public async getReach(params): Promise<IReachResponse> {
    return this.http.get('/reach', { params }).toPromise() as Promise<IReachResponse>;
  }

  public getGames(): Observable<any> {
    return this.http.get('/reach/games');
  }

  public getLanguages(): Observable<any> {
    return this.http.get('/reach/languages');
  }
}
