<div class="session__container">
  <div class="session__header">
    <h4>{{ session.title }}</h4>
    <div class="session__language"><span class="flag-icon flag-icon-{{ session.language === 'en' ? 'gb' : session.language }}"></span> {{ session.language }}</div>
  </div>
  <div class="session__details">
    <div class="session__info">
      <div class="session__game" *ngIf="game">
        <img [src]="game.art | boxArt: 80"/>
      </div>
      <div class="session__game-title">
        {{ game?.name }}
        <div class="session__date">{{ session.date | date : 'MMM d, y, h:mm a' }}</div>
      </div>
    </div>
    <div class="session__stats">
      <app-reach-overview-tile heading="Minutes watched">{{ session.minutesWatched | number }}</app-reach-overview-tile>
      <app-reach-overview-tile heading="Average concurrent viewers">{{ session.averageViewers | number:'1.0-0' }}</app-reach-overview-tile>
      <app-reach-overview-tile heading="Peak concurrent viewers">{{ session.peakViewers | number }}</app-reach-overview-tile>
      <app-reach-overview-tile heading="Total broadcast time">{{ session.timeOnline | hoursMinutes }}</app-reach-overview-tile>
    </div>
  </div>
</div>

