import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reach-overview-tile',
  templateUrl: './overview-tile.component.html',
  styleUrls: ['./overview-tile.component.scss']
})
export class OverviewTileComponent {
  @Input('heading') public heading: string;
}
