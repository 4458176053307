import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export interface IHeaderConfig {
  settingsAction?: Function;
}

@Injectable()
export class HeaderService {

  private configSubject = new ReplaySubject<IHeaderConfig>();

  constructor() {
    this.configSubject.next({} as IHeaderConfig);
  }

  public setConfig(config: IHeaderConfig) {
    this.configSubject.next(config);
  }

  public getConfig(): Observable<IHeaderConfig> {
    return this.configSubject.asObservable();
  }
}
