import { Component, Input } from '@angular/core';
import { IReachItem } from '../../interfaces/reach.interfaces';

@Component({
  selector: 'app-channel-component',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent {
  @Input('channel') channel: IReachItem;
}
