import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BrowserModule } from '@angular/platform-browser';
import { HoursMinutesPipe } from './pipes/hours-minutes.pipe';
import { GamesService } from './services/games.service';
import { BoxartPipe } from './pipes/boxart.pipe';
import { HeaderService } from './components/header/header.service';
import { MatIconModule } from '@angular/material/icon';
// import { AuthService } from './services/auth.service';
// import { TokenResolverService } from './services/token-resolver.service';

@NgModule({
  imports: [
    HttpClientModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    CommonModule,
    BrowserModule,
  ],
  declarations: [
    HeaderComponent,
    LoadingComponent,
    HoursMinutesPipe,
    BoxartPipe
  ],
  exports: [
    HeaderComponent,
    LoadingComponent,
    HoursMinutesPipe,
    BoxartPipe
  ],
  providers: [
    // AuthService,
    // TokenResolverService,
    GamesService,
    HeaderService,
  ]
})
export class SharedModule { }
