<mat-sidenav-container class="reach-index__container">
  <mat-sidenav #filterDrawer mode="push" [autoFocus]="false">
    <div class="reach-index__filter-container">
      <h2>Filter reach</h2>
      <form [formGroup]="filterForm" class="reach-index__filter-form">
        <mat-form-field>
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Languages</mat-label>
          <mat-select formControlName="languages" multiple>
            <mat-option *ngFor="let language of languages" [value]="language"><span class="flag-icon flag-icon-{{ language === 'en' ? 'gb' : language }}"></span> {{language}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Games</mat-label>
          <mat-select formControlName="games" multiple class="reach-filter__game-select">
            <mat-option #allGamesSelected class="reach-index__game-select-all" (click)="toggleAllSelected()" [value]="0">Select All</mat-option>
            <mat-option class="reach-index__game-select-option" *ngFor="let game of games" [value]="game.id"><img [src]="game.art | boxArt: 20"/> {{game.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="reach-filter__third-party">
          <mat-checkbox color="primary" class="reach-filter__third-party-input" formControlName="thirdParty">Include third party streams</mat-checkbox>
        </div>
      </form>

      <div class="reach-index__filter-actions">
        <div>
          <button mat-stroked-button color="warn" (click)="showSidebar()">Cancel</button>
        </div>
        <div>
          <button mat-flat-button color="primary" (click)="applyFilter()">Update Filter</button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="reach-index__main-container">
    <div class="reach-index__data-container" *ngIf="reachOverview; else loading">
      <div class="reach-index__overview-container">
        <h2>Overview</h2>
        <div class="reach-index__overview">
          <app-reach-overview-tile heading="Minutes watched">{{ reachOverview.minutesWatched | number}}</app-reach-overview-tile>
          <app-reach-overview-tile heading="Average concurrent viewers">{{ reachOverview.averageViewers | number:'1.0-0'}}</app-reach-overview-tile>
          <app-reach-overview-tile heading="Peak concurrent viewers">{{ reachOverview.peakViewers | number}}</app-reach-overview-tile>
          <app-reach-overview-tile heading="Total broadcast time">{{ reachOverview.timeOnline  | hoursMinutes }}</app-reach-overview-tile>
        </div>
      </div>
      <div class="reach-index__channels-container">
        <h2>Channels Breakdown</h2>
        <app-channel-component [channel]="channel" *ngFor="let channel of reachBreakdown"></app-channel-component>
      </div>
    </div>
    <ng-template #loading>
      <app-loading></app-loading>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>
