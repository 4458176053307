import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderService, IHeaderConfig } from './header.service';

@Component({
  selector: 'app-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public config$: Observable<IHeaderConfig>;

  public constructor(private headerService: HeaderService) {
    this.config$ = this.headerService.getConfig();
  }
}
