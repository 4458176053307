import { NgModule } from '@angular/core';
import { ReachIndexComponent } from './components/index/index.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ReachService } from './services/reach.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { OverviewTileComponent } from './components/overview-tile/overview-tile.component';
import { ChannelComponent } from './components/channel/channel.component';
import { MatIconModule } from '@angular/material/icon';
import { SessionComponent } from './components/session/session.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SharedModule,
  ],
  declarations: [
    ReachIndexComponent,
    OverviewTileComponent,
    ChannelComponent,
    SessionComponent,
  ],
  exports: [
    ReachIndexComponent,
  ],
  providers: [
    ReachService,
  ]
})
export class ReachModule {}
