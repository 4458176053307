import { Component, Input } from '@angular/core';
import { IReachBreakdownItem } from '../../interfaces/reach.interfaces';
import { IGame } from '../../../../shared/interfaces/game.interface';
import { GamesService } from '../../../../shared/services/games.service';

@Component({
  selector: 'app-reach-session',
  templateUrl: 'session.component.html',
  styleUrls: ['session.component.scss']
})
export class SessionComponent {
  @Input('session') public session: IReachBreakdownItem;
  public game: IGame;

  public constructor(private gameService: GamesService) {}

  public async ngOnInit() {
    this.game = await this.gameService.getGameById(this.session.gameId);
  }
}
