import { Component, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatDrawer } from '@angular/material/sidenav';
import { FormControl, FormGroup } from '@angular/forms';
import { sortBy } from 'lodash';

import { ReachService } from '../../services/reach.service';
import { IReachItem } from '../../../../../../../client/src/models/ReachItem';
import { IReachInfo } from '../../interfaces/reach.interfaces';
import { GamesService } from '../../../../shared/services/games.service';
import { IGame } from '../../../../shared/interfaces/game.interface';
import { HeaderService } from '../../../../shared/components/header/header.service';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reach-index',
  templateUrl: './index.component.html',
  styleUrls: ['index.component.scss'],
})
export class ReachIndexComponent {
  @ViewChild('filterDrawer') filterDrawer: MatDrawer;

  public reachBreakdown: IReachItem[];
  public reachOverview: IReachInfo;
  public games: IGame[];
  public languages: string[];
  public filterForm: FormGroup = new FormGroup({
    languages: new FormControl([]),
    games: new FormControl(''),
    startDate: new FormControl(moment().subtract(30, 'days').toDate()),
    endDate: new FormControl(new Date()),
    thirdParty: new FormControl(false),
  });
  private currentRouteParams: any = {};

  @ViewChild('allGamesSelected') private allGamesSelected: MatOption;

  constructor(
    private reachService: ReachService,
    private gameService: GamesService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.headerService.setConfig({
      settingsAction: this.showSidebar.bind(this),
    });
  }

  public async ngOnInit() {
    const [games, languages] = await Promise.all([
      this.gameService.getGames(),
      this.reachService.getLanguages().toPromise(),
    ]);

    this.games = sortBy(games, 'name');
    this.languages = languages;
    this.filterForm.controls.games.setValue([0, ...games.map((game) => game.id)]);
    this.filterForm.controls.languages.setValue(languages);
    this.currentRouteParams = this.filterForm.value;

    this.route.queryParams.subscribe((params) => {
      this.currentRouteParams = params;
      this.updateFormValues();
    });
  }

  public showSidebar() {
    this.filterDrawer.toggle();
  }

  public async applyFilter() {
    this.updateRouteParams();
    await this.getReach();
  }

  public async getReach() {
    this.filterDrawer.close();
    this.reachBreakdown = undefined;
    this.reachOverview = undefined;
    const params = { ...this.filterForm.getRawValue() };

    if (params.languages.length === this.languages.length) {
      delete params.languages;
    }

    if (params.games.length === this.games.length) {
      delete params.games;
    }

    const { breakdown, overview } = await this.reachService.getReach(params);
    this.reachBreakdown = breakdown;
    this.reachOverview = overview;
  }

  public toggleAllSelected() {
    if (this.allGamesSelected.selected) {
      this.filterForm.controls.games
        .patchValue([0,...this.games.map(item => item.id)]);
    } else {
      this.filterForm.controls.games.patchValue([]);
    }
  }

  private updateRouteParams() {
    const formValues = this.filterForm.value;
    const toChange = {}
    Object.keys(formValues).forEach((key) => {
      let formValue = formValues[key];
      if (!this.currentRouteParams[key] || this.currentRouteParams[key] !== formValue) {
        switch(key) {
          case 'games':
            if (formValue.length === (this.games.length + 1)) {
              break;
            }
          case 'languages':
            if (formValue.length === this.languages.length) {
              break;
            }
            formValue = JSON.stringify(formValue);
            toChange[key] = formValue;
            break;
          case 'startDate':
          case 'endDate':
            formValue = formValue.toISOString();
          default:
            toChange[key] = formValue;
        }
      }
    });

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: toChange,
        queryParamsHandling: 'merge'
      });
  }
  private updateFormValues() {
    Object.keys(this.currentRouteParams).forEach((key) => {
      let routeParamValue;

      switch (key) {
        case 'games':
        case 'languages':
          routeParamValue = JSON.parse(this.currentRouteParams[key]);
          break;
        case 'startDate':
        case 'endDate':
        default:
          routeParamValue = this.currentRouteParams[key];
      }

      if (this.filterForm.controls[key] && this.filterForm.controls[key].value !== routeParamValue) {
        this.filterForm.controls[key].setValue(routeParamValue)
      }
    });

    this.getReach();
  }
}
