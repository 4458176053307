import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutes'
})
export class HoursMinutesPipe implements PipeTransform {

  transform(value: number): string {

    const hours: number = Math.floor(value / 3600);
    const minutes: number = ((value - (hours * 3600)) / 60);

    let response = '';

    if (hours > 0) {
      response += `${hours} hrs `;
    }

    if (minutes > 0) {
      response += `${ Math.round(minutes) } mins`;
    }

    return response;
  }
}
