import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private configService: ConfigService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const clonedRequest = req.clone({
      url: !req.url.includes('/assets') ? `${this.configService.getConfig().API_HOST}${req.url}` : req.url,
      // headers: req.headers.set('Authorization', `Bearer ${this.authService.getToken()}`),
    });

    return next.handle(clonedRequest);
  }
}
