import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface IConfig {
  PORT: string;
  API_HOST: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: IConfig;

  constructor(private http: HttpClient) {}

  public async loadConfig(): Promise<void> {
    this.config = await this.http.get('/assets/config/config.json').toPromise() as IConfig;
  }

  public getConfig(): IConfig {
    return this.config;
  }
}
