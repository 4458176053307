import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `<div class="loading__container">
    <div class="loading__icon">
      <img src="/assets/img/endpoint-logo.svg"/>
    </div>
  </div>`,
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {}
