import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { keyBy } from 'lodash';

import { IGame } from '../interfaces/game.interface';

@Injectable()
export class GamesService {
  private games: IGame[];
  private gamesById: Record<string, IGame>;

  constructor(private http: HttpClient) {
    this.getGames();
  }

  public async getGames() {
    if (this.games) {
      return this.games;
    }

    this.games = await this.http.get('/reach/games').toPromise() as IGame[];
    this.gamesById = keyBy(this.games, 'id');
    return this.games;
  }

  public async getGameById(id: string) {
    await this.getGames();
    return this.gamesById[id];
  }

}
