<div class="channel__container">
  <div class="channel__overview">
    <div class="channel__user">
      <div class="channel__avatar">
        <img class="hex-clip" src="{{ channel.user.profile_image_url }}"/>
      </div>
      <div class="channel__name">
        {{ channel.user.display_name }} <span *ngIf="channel.user.broadcaster_type === 'partner'"><mat-icon class="channel__partner-icon">verified_user</mat-icon></span>
      </div>
    </div>
    <div class="channel__stats">
      <app-reach-overview-tile heading="Minutes watched">{{ channel.overview.minutesWatched | number }}</app-reach-overview-tile>
      <app-reach-overview-tile heading="Average concurrent viewers">{{ channel.overview.averageViewers | number:'1.0-0' }}</app-reach-overview-tile>
      <app-reach-overview-tile heading="Peak concurrent viewers">{{ channel.overview.peakViewers | number }}</app-reach-overview-tile>
      <app-reach-overview-tile heading="Total broadcast time">{{ channel.overview.timeOnline | hoursMinutes }}</app-reach-overview-tile>
    </div>
  </div>
  <div class="channel__breakdown-container">
    <input type="checkbox" class="channel__breakdown-toggle" id="check-{{ channel.user.id }}"/>
    <label class="channel__breakdown-label" for="check-{{ channel.user.id}}">Show Sessions Breakdown</label>
    <div class="channel__breakdown-items">
      <app-reach-session [session]="session" *ngFor="let session of channel.breakdown"></app-reach-session>
    </div>
  </div>
</div>
