import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';

@Pipe({
  name: 'boxArt'
})
export class BoxartPipe implements PipeTransform {

  transform(value: string, width: number = 50 ): string {
    if (!value || !isString(value)) {
      return '';
    }
    const ratio = 1.33;
    const height = Math.round(width * ratio);
    return value.replace('{height}', height.toString(10)).replace('{width}', width.toString(10));
  }
}





